@media ((min-width: 250px) and (max-width: 767px)) {
  .website-div {
    justify-content: center;
    background-color: #fff;
    display: flex;
    max-width: 480px;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    box-sizing: border-box;

    .div-2 {
      background-color: #fff;
      display: flex;
      width: 100%;
      flex-direction: column;
      box-sizing: border-box;
    }
    .div-3 {
      box-sizing: border-box;
      justify-content: space-between;
      background-color: #fbfbfb;
      display: flex;
      width: 100%;
      gap: 16px;
      font-size: 16px;
      color: #1d1a1c;
      font-weight: 400;
      line-height: 150%;
      padding: 4px 10px;
      position: sticky;
      top: 0px;
      z-index: 2;
    }
    .div-4 {
      justify-content: center;
      display: flex;
      gap: 5px;
      padding: 5px;
      position: relative;
    }
    .img {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 28px;
    }
    .div-5 {
      display: flex;
      align-items: center;
      font-family: Josefin Sans, sans-serif;
      font-size: 25px;
    }
    .img-2 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 25px;
      margin: auto 0;
      cursor: pointer;
    }
    .div-6 {
      display: flex;
      margin-top: 57px;
      flex-direction: column;
    }
    .div-7 {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      color: #676161;
      text-overflow: ellipsis;
      letter-spacing: -0.3px;
      margin: 0 23px;
      font: 700 30px/32px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-8 {
      color: #333;
      letter-spacing: -0.2px;
      margin: 15px 23px 0;
      font: 500 20px Josefin Sans, sans-serif;
    }
    .div-9 {
      justify-content: center;
      display: flex;
      margin-top: 51px;
      flex-direction: column;
      padding: 8px 5px;
    }
    .div-10 {
      display: flex;
      gap: 8px;
      padding: 0 2px;
    }
    .img-3 {
      aspect-ratio: 2.56;
      object-fit: auto;
      object-position: center;
      width: 199px;
      max-width: 100%;
    }
    .img-4 {
      aspect-ratio: 1.23;
      object-fit: auto;
      object-position: center;
      width: 100%;
      flex: 1;
    }
    .div-11 {
      display: flex;
      margin-top: 8px;
      gap: 8px;
      padding: 0 2px;
    }
    .img-5 {
      aspect-ratio: 1.79;
      object-fit: auto;
      object-position: center;
      width: 137px;
      border-radius: 9.704px;
      max-width: 100%;
    }
    .img-6 {
      aspect-ratio: 2.04;
      object-fit: auto;
      object-position: center;
      width: 100%;
      flex: 1;
    }
    .div-12 {
      display: flex;
      margin-top: 7px;
      gap: 8px;
      justify-content: space-between;
      padding: 0 2px;
    }
    .img-7 {
      aspect-ratio: 1.33;
      object-fit: auto;
      object-position: center;
      width: 102px;
      max-width: 100%;
    }
    .img-8 {
      aspect-ratio: 2.5;
      object-fit: auto;
      object-position: center;
      width: 100%;
      max-width: 100%;
    }
    .div-13 {
      display: none !important;
      align-self: start;
      display: flex;
      margin-top: 18px;
      gap: 4px;
      font-size: 7px;
      color: #000;
      font-weight: 400;
      letter-spacing: -0.07px;
    }
    .img-9 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 11px;
    }
    .div-14 {
      font-family: Josefin Sans, sans-serif;
      margin: auto 0;
    }
    .div-15 {
      background-color: #e6f5f8;
      display: flex;
      margin-top: 15px;
      flex-direction: column;
      font-size: 14px;
      color: var(--Secondary_Cube, #0065a8);
      line-height: 20px;
      padding: 19px 0 39px;
    }
    .div-16 {
      display: flex;
      flex-direction: column;
      padding: 0 20px 11px;
    }
    .div-17 {
      letter-spacing: -0.3px;
      font: 700 30px/130% Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-18 {
      color: #333;
      font-family: Josefin Sans, sans-serif;
      font-weight: 400;
      margin-top: 12px;
    }
    .div-19 {
      font-family: Playfair Display, sans-serif;
      font-weight: 500;
      letter-spacing: -0.14px;
      margin-top: 8px;
    }
    .img-10 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 188px;
      align-self: center;
      margin-top: 9px;
      max-width: 100%;
    }
    .div-20 {
      color: var(--Secondary_Cube, #0065a8);
      text-align: center;
      margin-top: 20px;
      font: 700 30px Josefin Sans, sans-serif;
    }
    .div-21 {
      align-self: center;
      display: flex;
      margin-top: 38px;
      width: 250px;
      max-width: 100%;
      flex-direction: column;
      padding: 0 21px;
      gap: 20px;
    }
    .div-22 {
      border-radius: 10px;
      /* background-color: #242537; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      color: #fff;
      /* padding: 40px 60px 24px; */
      min-height: 192px;
      position: relative;
      .img-11 {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 210px;
        height: auto;
        z-index: 0;
      }
    }
    .div-23 {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: start;
      position: relative;
      /* padding: 40px 25px 40px 0; */
      height: 100%;
      padding: 15px;
      padding-bottom: 10px;
      ul {
        padding-left: 20px;
      }
    }
    .div-24 {
      font: 700 16px Josefin Sans, sans-serif;
      /* text-wrap: nowrap; */
      z-index: 1;
    }
    .div-25 {
      margin-top: 4px;
      font: 500 10px Josefin Sans, sans-serif;
      z-index: 1;
    }
    .div-26 {
      border-radius: 10px;
      /* background-color: #242537; */
      display: flex;
      margin-top: 15px;
      align-items: center;
      color: #fff;
      justify-content: center;
      width: 100%;
      height: 184px;
    }
    .div-27 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      width: 100%;
      padding: 52px 7px;
      .img-11 {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: auto;
        z-index: 0;
      }
    }
    .img-11 {
      position: absolute;
      top: 0;
      object-fit: cover;
      object-position: center;
    }
    .div-28 {
      position: relative;
      font: 700 16px Josefin Sans, sans-serif;
    }
    .div-29 {
      position: relative;
      margin-top: 4px;
      font: 500 10px Josefin Sans, sans-serif;
    }
    .div-30 {
      position: relative;
      border-radius: 10px;
      /* background-color: #242537; */
      display: flex;
      margin-top: 15px;
      align-items: center;
      justify-content: center;
      height: 184px;
      padding: 0 19px;
      .img-11 {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: auto;
        z-index: 0;
      }
    }
    .img-12 {
      aspect-ratio: 1.32;
      object-fit: auto;
      object-position: center;
      width: 145px;
    }
    .div-31 {
      color: #000;
      text-align: center;
      align-self: center;
      margin-top: 38px;
      font: 500 20px Josefin Sans, sans-serif;
    }
    .img-13 {
      aspect-ratio: 1.19;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: center;
      margin-top: 18px;
      max-width: 285px;
    }
    .div-32 {
      background-color: #e6f5f8;
      display: flex;
      margin-top: 36px;
      flex-direction: column;
      padding: 80px 16px 20px;
    }
    .div-33 {
      color: var(--Secondary_Cube, #0065a8);
      align-self: start;
      margin-left: 15px;
      font: 700 30px Josefin Sans, sans-serif;
    }
    .div-34 {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
    .div-35 {
      color: #333;
      letter-spacing: -0.12px;
      font: 500 12px/16px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-36 {
      color: #605e5e;
      margin-top: 8px;
      font: 400 10px/14px Inter, sans-serif;
    }
    .img-14 {
      aspect-ratio: 1.49;
      object-fit: auto;
      object-position: center;
      width: 234px;
      align-self: center;
      margin-top: 20px;
      max-width: 100%;
    }
    .div-37 {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
    .div-38 {
      color: var(--Secondary_Cube, #0065a8);
      text-align: center;
      letter-spacing: -0.3px;
      align-self: center;
      font: 700 30px Josefin Sans, sans-serif;
    }
    .div-39 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10.732px;
      overflow: hidden;
      position: relative;
      aspect-ratio: 0.9;
      margin-top: 20px;
      font-size: 10px;
      color: #fff;
      font-weight: 400;
      line-height: 160%;
      padding: 15px;
      width: auto;
      height: 500px;
    }
    .img-15 {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .div-40 {
      position: relative;
      color: #eee;
      text-align: center;
      font: 600 24px/130% Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-41 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 2.683px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 11px;
      justify-content: center;
      padding: 10px;
    }
    .div-42 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 2.683px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 11px;
      justify-content: center;
      padding: 10px;
    }
    .div-43 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 2.683px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 11px;
      justify-content: center;
      padding: 10px;
    }
    .div-44 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 2.683px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 11px;
      white-space: nowrap;
      justify-content: center;
      padding: 10px;
    }
    .div-45 {
      position: relative;
      justify-content: center;
      border-radius: 5.366px;
      border-color: rgba(0, 101, 168, 1);
      border-style: solid;
      border-width: 1px;
      align-self: center;
      margin-top: 12px;
      white-space: nowrap;
      text-align: center;
      padding: 10px 16px;
      font: 11px Inter, sans-serif;
      cursor: pointer;
    }
    .div-46 {
      position: relative;
      text-align: center;
      margin-top: 10px;
      font: 9px Lato, sans-serif;
    }
    .div-47 {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      justify-content: center;
      padding: 2px 9px;
    }
    .img-16 {
      aspect-ratio: 1.41;
      object-fit: auto;
      object-position: center;
      width: 100%;
    }
    .div-48 {
      box-sizing: border-box;
      justify-content: center;
      background-color: #313553;
      display: flex;
      margin-top: 16px;
      flex-direction: column;
      color: #fff;
      font-weight: 400;
      line-height: 150%;
      padding: 13px;
    }
    .div-49 {
      align-items: start;
      display: flex;
      padding-right: 34px;
      gap: 14px;
    }
    .div-50 {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 8px 0;
    }
    .div-51 {
      display: flex;
      gap: 2px;
      font-size: 12px;
      justify-content: flex-start;
      align-items: center;
    }
    .img-17 {
      aspect-ratio: 0.93;
      object-fit: auto;
      object-position: center;
      width: 12px;
    }
    .div-52 {
      font-family: Inter, sans-serif;
    }
    .div-53 {
      margin-top: 6px;
      font: 9px Source Sans Pro, sans-serif;
    }
    .div-54 {
      max-width: 180px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-size: 9px;
    }
    .div-55 {
      color: #eee;
      font: 900 8px/130% Playfair Display, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-56 {
      font-family: Inter, sans-serif;
      line-height: 9px;
      margin-top: 4px;
    }
    .div-57 {
      font-family: Inter, sans-serif;
      margin-top: 6px;
    }
    .div-58 {
      font-family: Inter, sans-serif;
      margin-top: 5px;
    }
    .div-59 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      font-size: 9px;
      color: #eee;
    }
    .div-60 {
      color: #fff;
      font: 700 7px/160% Inter, sans-serif;
      font-size: 10px;
      white-space: nowrap;
    }
    .div-61 {
      font-family: Lato, sans-serif;
      margin-top: 4px;
    }
    .div-62 {
      font-family: Lato, sans-serif;
      margin-top: 4px;
    }
    .div-63 {
      font-family: Lato, sans-serif;
      margin-top: 4px;
    }
    .div-64 {
      font-family: Lato, sans-serif;
      margin-top: 4px;
    }
    .div-65 {
      font-family: Lato, sans-serif;
      margin-top: 4px;
    }

    .wrapper {
      display: none;
      position: absolute;
      top: 50px;
      right: 0px;
      height: 170px;
      width: max-content;
      background: #ffffff;
      color: black;
      z-index: 10;
      border-radius: 12px 2px 12px 12px;
      transition: all 0.6s ease-in-out;
      border: 2px solid #0065a8;
      flex-direction: column;
      padding: 30px 20px;
      .no-text-wrap {
        text-wrap: nowrap;
      }
      .remove-anchor-styles {
        text-decoration: none;
        color: unset;
        font-size: 16px;
      }
      .employee-login {
        cursor: pointer;
        border-radius: 8px;
        background-color: var(--Secondary_Cube, #0065a8);
        align-self: stretch;
        display: flex;
        gap: 4px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        padding: 5px 10px;
        width: 190px;
      }
      .corporate-login {
        cursor: pointer;
        justify-content: center;
        border-radius: 8px;
        border-color: rgba(0, 101, 168, 1);
        border-style: solid;
        border-width: 2px;
        align-self: stretch;
        color: #333;
        text-align: center;
        padding: 5px;
        margin-top: 25px;
        width: 300px;
        font: 14px/24px Josefin Sans, sans-serif;
        cursor: pointer;
      }
    }

    .open-menu {
      display: flex;
    }

    .menu-btn {
      position: fixed;
      /* z-index: 2; */
      right: 5px;
      /* top: 5%; */
      height: 55px;
      width: 55px;
      text-align: center;
      line-height: 50px;
      border-radius: 50%;
      font-size: 20px;
      cursor: pointer;
      color: #fff;
      /* background: #242537;s */
      z-index: 1000;
      transition: all 0.3s ease-in-out;
      .not-img-2 {
        display: none;
      }
    }

    .close {
      z-index: 1;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: background 0.6s;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      text-align: center;
      width: 100%;
      color: #202020;
    }
  }
}

@media ((min-width: 250px) and (max-width: 450px)) {
  .website-div {
    .wrapper {
      width: 87vw !important;
    }
    .employee-login {
      font-size: 11px !important;
      width: min-content !important;
    }
    .corporate-login {
      width: min-content !important;
      font-size: 11px !important;
    }
    .remove-anchor-styles {
      font-size: 11px !important;
      text-wrap: nowrap;
    }
  }
}

@media ((min-width: 250px) and (max-width: 450px)) {
  .wrapper {
    width: 87vw !important;
  }
  .employee-login {
    font-size: 11px !important;
    width: min-content !important;
  }
  .corporate-login {
    width: min-content !important;
    font-size: 11px !important;
  }
  .remove-anchor-styles {
    font-size: 11px !important;
    text-wrap: nowrap;
  }
}
