@media (min-width: 768px) {
  .website-div {
    width: 100%;
    max-width: 100%;
    background-color: #f8f8ff;
    color: #fff;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    box-sizing: border-box;

    .div-2 {
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      border-radius: 64px;
      background-color: #fbfbfb;
      display: flex;
      width: 100%;
      gap: 20px;
      font-weight: 500;
      line-height: 150%;
      padding: 11px 8px;
      position: sticky;
      top: 0px;
      z-index: 2;
      .remove-anchor-styles {
        text-decoration: none;
        color: unset;
        font-size: 16px;
      }
    }
    @media (max-width: 768px) {
      .div-2 {
        max-width: 100%;
        flex-wrap: wrap;
        padding-right: 20px;
      }
    }
    .div-3 {
      cursor: pointer;
      justify-content: center;
      border-radius: 8px;
      border-color: rgba(0, 101, 168, 1);
      border-style: solid;
      border-width: 2px;
      align-self: stretch;
      color: #333;
      text-align: center;
      margin: auto 0;
      padding: 5px 20px;
      font: 14px/24px Josefin Sans, sans-serif;
      cursor: pointer;
    }
    .div-4 {
      justify-content: center;
      align-self: center;
      display: flex;
      gap: 8px;
      font-size: 30px;
      color: #1d1a1c;
      font-weight: 400;
    }
    .img {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 35px;
      margin: auto 0;
    }
    .div-5 {
      display: flex;
      align-items: center;
      font-family: Josefin Sans, sans-serif;
    }
    .div-6 {
      cursor: pointer;
      border-radius: 8px;
      background-color: var(--Secondary_Cube, #0065a8);
      align-self: stretch;
      display: flex;
      gap: 4px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: auto 0;
      padding: 5px 20px;
    }
    .img-2 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 22px;
      align-self: start;
    }
    .div-7 {
      font-family: Josefin Sans, sans-serif;
      cursor: pointer;
    }
    .div-8 {
      display: flex;
      margin-top: 50px;
      width: 100%;
      flex-direction: column;
      /* padding: 0 80px; */ /* causing horizontal scroll */
    }
    @media (max-width: 768px) {
      .div-8 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .div-9 {
      align-self: center;
      width: 100%;
      max-width: 1095px;
    }
    @media (max-width: 768px) {
      .div-9 {
        max-width: 100%;
      }
    }
    .div-10 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-10 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column {
        width: 100%;
      }
    }
    .div-11 {
      justify-content: center;
      align-self: stretch;
      display: flex;
      margin-top: 14px;
      flex-direction: column;
    }
    @media (max-width: 768px) {
      .div-11 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-12 {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      color: #676161;
      text-overflow: ellipsis;
      letter-spacing: -0.66px;
      font: 700 66px/71px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-12 {
        max-width: 100%;
        font-size: 40px;
        line-height: 48px;
      }
    }
    .div-13 {
      color: #333;
      margin-top: 30px;
      font: 500 30px/42px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-13 {
        max-width: 100%;
      }
    }
    .column-2 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-2 {
        width: 100%;
      }
    }
    .div-14 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding: 12px;
    }
    @media (max-width: 768px) {
      .div-14 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-15 {
    }
    @media (max-width: 768px) {
      .div-15 {
        max-width: 100%;
      }
    }
    .div-16 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-16 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-3 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 68%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column-3 {
        width: 100%;
      }
    }
    .img-3 {
      aspect-ratio: 2.56;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-3 {
        margin-top: 12px;
      }
    }
    .column-4 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 32%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-4 {
        width: 100%;
      }
    }
    .img-4 {
      aspect-ratio: 1.23;
      object-fit: auto;
      object-position: center;
      width: 154px;
      align-self: stretch;
      max-width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-4 {
        margin-top: 12px;
      }
    }
    .div-17 {
      margin-top: 12px;
    }
    @media (max-width: 768px) {
      .div-17 {
        max-width: 100%;
      }
    }
    .div-18 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-18 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .img-5 {
      aspect-ratio: 1.79;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      border-radius: 15.593px;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-5 {
        margin-top: 13px;
      }
    }
    .column-5 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-5 {
        width: 100%;
      }
    }
    .img-6 {
      aspect-ratio: 2.04;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-6 {
        margin-top: 13px;
      }
    }
    .div-19 {
      margin-top: 12px;
      padding: 0 2px;
    }
    @media (max-width: 768px) {
      .div-19 {
        max-width: 100%;
      }
    }
    .div-20 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-20 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-6 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 35%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column-6 {
        width: 100%;
      }
    }
    .img-7 {
      aspect-ratio: 1.32;
      object-fit: auto;
      object-position: center;
      width: 164px;
      align-self: stretch;
      max-width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-7 {
        margin-top: 12px;
      }
    }
    .column-7 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 65%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-7 {
        width: 100%;
      }
    }
    .img-8 {
      aspect-ratio: 2.5;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-8 {
        margin-top: 12px;
      }
    }
    .div-21 {
      align-self: start;
      display: flex;
      gap: 10px;
      font-size: 16px;
      color: #000;
      font-weight: 400;
      letter-spacing: -0.24px;
      margin: 73px 0 0 32px;
    }
    @media (max-width: 768px) {
      .div-21 {
        margin: 40px 0 0 10px;
      }
    }
    .img-9 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 24px;
    }
    .div-22 {
      font-family: Inter, sans-serif;
      margin: auto 0;
    }
    .div-23 {
      box-sizing: border-box;
      background-color: #e6f5f8;
      margin-top: 61px;
      width: 100%;
      padding: 32px 10px 80px 78px;
    }
    @media (max-width: 768px) {
      .div-23 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .div-24 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-24 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-8 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 60%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column-8 {
        width: 100%;
      }
    }
    .div-25 {
      display: flex;
      flex-direction: column;
      color: var(--Secondary_Cube, #0065a8);
    }
    @media (max-width: 768px) {
      .div-25 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-26 {
      font: 700 50px/130% Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-26 {
        max-width: 100%;
        font-size: 40px;
      }
    }
    .div-27 {
      color: #333;
      margin-top: 24px;
      font: 400 30px/42px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-27 {
        max-width: 100%;
      }
    }
    .div-28 {
      letter-spacing: -0.34px;
      margin-top: 16px;
      font: 500 34px/48px Playfair Display, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-28 {
        max-width: 100%;
      }
    }
    .column-9 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 40%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-9 {
        width: 100%;
      }
    }
    .img-10 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      margin: auto 0;
    }
    @media (max-width: 768px) {
      .img-10 {
        margin-top: 40px;
      }
    }
    .div-29 {
      align-self: center;
      color: var(--Secondary_Cube, #0065a8);
      text-align: center;
      margin-top: 60px;
      font: 700 50px Josefin Sans, sans-serif;
    }
    @media (max-width: 768px) {
      .div-29 {
        max-width: 100%;
        margin-top: 40px;
        font-size: 40px;
      }
    }
    .div-30 {
      justify-content: center;
      align-self: center;
      margin-top: 74px;
      width: 100%;
      max-width: 1136px;
      padding: 0 40px;
    }
    @media (max-width: 768px) {
      .div-30 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .div-31 {
      /* gap: 5px; */
      display: flex;
    }
    @media (max-width: 768px) {
      .div-31 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    @media (max-width: 850px) {
      .div-40 {
        margin-left: 20px !important;
      }
      .div-30 {
        padding: 0 15px;
      }
    }
    .column-10 {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: normal;
    }
    @media (max-width: 768px) {
      .column-10 {
        width: 100%;
      }
    }
    .div-32 {
      justify-content: center;
      align-items: center;
      border-radius: 15.385px;
      display: flex;
      gap: 0px;
      color: #fff;
      width: 100%;
      height: 100%;
      padding: 15px;
    }
    @media (max-width: 768px) {
      .div-32 {
        margin-top: 36px;
        padding-left: 20px;
      }
    }
    .div-33 {
      /* align-self: end; */
      display: flex;
      margin: 13px;
      flex-direction: column;
      width: fit-content;
    }
    .div-34 {
      font: 700 30px Josefin Sans, sans-serif;
    }
    .div-35 {
      margin-top: 9px;
      font: 500 18px/20px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    /* .div-36 {
    align-self: start;
    width: 215px;
    height: 214px;
  } */
    .column-11 {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: normal;
      /* width: 33%; */
    }
    @media (max-width: 768px) {
      .column-11 {
        width: 100%;
      }
    }
    .div-37 {
      border-radius: 29.425px;
      /* background-color: #242537; */
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      justify-content: center;
      aspect-ratio: 1;
      width: 100%;
      height: auto;
      min-height: 240px;
    }
    @media (max-width: 768px) {
      .div-37 {
        margin-top: 36px;
      }
    }
    .div-38 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: baseline;
      text-align: start;
      overflow: hidden;
      position: relative;
      aspect-ratio: 1;
      height: 100%;
      /* padding: 10px 15px; */
      /* padding-bottom: 20px; */
    }
    @media (max-width: 1100px) {
      .div-38 {
        justify-content: flex-start;
      }
      .div-39 {
        padding-top: 10px;
      }
    }
    @media (max-width: 768px) {
      .div-38 {
        padding: 0 20px;
      }
    }
    .img-11 {
      position: absolute;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: auto;
    }
    .div-39 {
      text-align: left;
      position: relative;
      margin-top: 14px;
      font: 600 20px Josefin Sans, sans-serif;
      padding-right: 65px;
      /* padding-left: 5px; */
    }
    .div-40 {
      position: relative;
      margin: 13px 10px 13px 40px;
      font: 500 15px/17px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      padding-bottom: 40px;
      ul {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .column-12 {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: normal;
      /* width: 33%; */
    }
    @media (max-width: 768px) {
      .column-12 {
        width: 100%;
      }
    }
    .div-41 {
      border-radius: 23.078px;
      /* background-color: #242537; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      width: 100%;
      /* padding: 0 36px; */
      width: 100%;
      height: auto;
      min-height: 240px;
    }
    @media (max-width: 768px) {
      .div-41 {
        margin-top: 36px;
        padding: 0 20px;
      }
    }
    .img-12 {
      /* aspect-ratio: 1.32; */
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
    .div-42 {
      align-self: center;
      margin-top: 30px;
      width: 100%;
      max-width: 1099px;
      padding: 0 20px;
    }
    @media (max-width: 768px) {
      .div-42 {
        max-width: 100%;
      }
    }
    .div-43 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-43 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .div-44 {
      color: #000;
      align-self: stretch;
      margin: auto 0;
      font: 500 36px/50px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-44 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .column-13 {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
    }
    @media (max-width: 768px) {
      .column-13 {
        width: 100%;
      }
    }
    .img-13 {
      aspect-ratio: 1.19;
      object-fit: auto;
      object-position: center;
      width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 768px) {
      .img-13 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-45 {
      box-sizing: border-box;
      background-color: #e6f5f8;
      display: flex;
      margin-top: 80px;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 40px 60px;
      cursor: pointer;
    }
    @media (max-width: 768px) {
      .div-45 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .div-46 {
      justify-content: center;
      width: 100%;
      max-width: 1130px;
      margin: 38px 0 70px;
    }
    @media (max-width: 768px) {
      .div-46 {
        max-width: 100%;
        margin: 40px 0;
      }
    }
    .div-47 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-47 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-14 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 56%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column-14 {
        width: 100%;
      }
    }
    .div-48 {
      justify-content: center;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      font-size: 22px;
      padding: 20px 0;
    }
    @media (max-width: 768px) {
      .div-48 {
        max-width: 100%;
        margin-top: 33px;
      }
    }
    .div-49 {
      color: var(--Secondary_Cube, #0065a8);
      font: 700 50px Josefin Sans, sans-serif;
    }
    @media (max-width: 768px) {
      .div-49 {
        max-width: 100%;
        font-size: 40px;
      }
    }
    .div-50 {
      color: #333;
      font-family: Josefin Sans, sans-serif;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: -0.22px;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      .div-50 {
        max-width: 100%;
      }
    }
    .div-51 {
      color: #9a9a9a;
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 31px;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      .div-51 {
        max-width: 100%;
      }
    }
    .column-15 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 44%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-15 {
        width: 100%;
      }
    }
    .img-14 {
      aspect-ratio: 1.49;
      object-fit: auto;
      object-position: center;
      width: 100%;
      align-self: stretch;
      margin: auto 0;
    }
    @media (max-width: 768px) {
      .img-14 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-52 {
      color: var(--Secondary_Cube, #0065a8);
      align-self: center;
      margin-top: 69px;
      font: 700 50px Josefin Sans, sans-serif;
    }
    @media (max-width: 768px) {
      .div-52 {
        margin-top: 40px;
        font-size: 40px;
      }
    }
    .div-53 {
      align-self: center;
      margin-top: 51px;
      width: 100%;
      max-width: 1106px;
    }
    @media (max-width: 768px) {
      .div-53 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .div-54 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 768px) {
      .div-54 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-16 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 46%;
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      .column-16 {
        width: 100%;
      }
    }
    .div-55 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      min-height: 574px;
      flex-grow: 1;
      font-size: 14px;
      color: #fff !important;
      font-weight: 400;
      line-height: 160%;
      padding: 32px;
    }
    @media (max-width: 768px) {
      .div-55 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .img-15 {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .div-56 {
      position: relative;
      color: #eee;
      font: 600 36px/47px Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-57 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 4px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 16px;
      justify-content: center;
      padding-right: 10px;
    }
    @media (max-width: 768px) {
      .div-57 {
        padding-right: 20px;
      }
    }
    .div-58 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 4px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 16px;
      justify-content: center;
      padding-right: 10px;
    }
    @media (max-width: 768px) {
      .div-58 {
        padding-right: 20px;
      }
    }
    .div-59 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 4px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 16px;
      justify-content: center;
      padding-right: 10px;
    }
    @media (max-width: 768px) {
      .div-59 {
        padding-right: 20px;
      }
    }
    .div-60 {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-family: Josefin Sans, sans-serif;
      align-items: start;
      border-radius: 4px;
      border-color: rgba(233, 233, 233, 1);
      border-style: solid;
      border-top-width: 1px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 1px;
      background-color: #222831;
      margin-top: 16px;
      white-space: nowrap;
      justify-content: center;
      padding-right: 10px;
    }
    @media (max-width: 768px) {
      .div-60 {
        padding-right: 20px;
        white-space: initial;
      }
    }
    .div-61 {
      position: relative;
      justify-content: center;
      border-radius: 8px;
      border-color: rgba(0, 101, 168, 1);
      border-style: solid;
      border-width: 2px;
      align-self: center;
      margin-top: 16px;
      white-space: nowrap;
      text-align: center;
      padding: 14px 24px;
      font: 16px Josefin Sans, sans-serif;
      cursor: pointer;
    }
    @media (max-width: 768px) {
      .div-61 {
        white-space: initial;
        padding: 0 20px;
      }
    }
    .div-62 {
      position: relative;
      text-align: center;
      margin-top: 16px;
      font: 12px Josefin Sans, sans-serif;
    }
    .column-17 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 54%;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      .column-17 {
        width: 100%;
      }
    }
    .div-63 {
      display: flex;
      margin-top: 14px;
      flex-direction: column;
      font-size: 18px;
      color: #000;
      font-weight: 500;
    }
    @media (max-width: 768px) {
      .div-63 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .img-16 {
      aspect-ratio: 2;
      object-fit: auto;
      object-position: center;
      width: 100%;
    }
    @media (max-width: 768px) {
      .img-16 {
        max-width: 100%;
      }
    }
    .div-64 {
      justify-content: center;
      display: flex;
      margin-top: 21px;
      padding-right: 20px;
      gap: 20px;
      font-size: 14px;
    }
    @media (max-width: 768px) {
      .div-64 {
        flex-wrap: wrap;
      }
    }
    .img-17 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 40px;
      align-self: start;
    }
    .div-65 {
      font-family: Josefin Sans, sans-serif;
    }
    @media (max-width: 768px) {
      .div-65 {
        max-width: 100%;
      }
    }
    .div-66 {
      justify-content: center;
      align-self: start;
      display: flex;
      margin-top: 25px;
      padding-right: 20px;
      gap: 20px;
    }
    .img-18 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 40px;
    }
    .div-67 {
      font-family: Josefin Sans, sans-serif;
      margin: auto 0;
    }
    .div-68 {
      justify-content: center;
      align-self: start;
      display: flex;
      margin-top: 25px;
      padding-right: 20px;
      gap: 20px;
      white-space: nowrap;
    }
    @media (max-width: 768px) {
      .div-68 {
        white-space: initial;
      }
    }
    .img-19 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 40px;
    }
    .div-69 {
      font-family: Josefin Sans, sans-serif;
      margin: auto 0;
    }
    .div-70 {
      box-sizing: border-box;
      background-color: #313553;
      display: flex;
      margin-top: 110px;
      /* width: 100%; */
      flex-direction: column;
      color: #fff;
      font-weight: 400;
      line-height: 150%;
      justify-content: center;
      padding: 48px 44px;
    }
    @media (max-width: 768px) {
      .div-70 {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
      }
    }
    .div-71 {
      align-items: start;
      align-content: flex-start;
      flex-wrap: wrap;
      display: flex;
      padding-right: 80px;
      gap: 20px;
    }
    @media (max-width: 768px) {
      .div-71 {
        max-width: 100%;
        padding-right: 20px;
      }
    }
    .div-72 {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .div-73 {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 34px;
    }
    .img-20 {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 44px;
      align-self: start;
    }
    .div-74 {
      font-family: Josefin Sans, sans-serif;
    }
    .div-75 {
      margin-top: 20px;
      font: 16px Josefin Sans, sans-serif;
    }
    .div-76 {
      max-width: 640px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
    }
    @media (max-width: 768px) {
      .div-76 {
        max-width: 100%;
      }
    }
    .div-77 {
      color: #eee;
      font: 700 30px/130% Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 768px) {
      .div-77 {
        max-width: 100%;
      }
    }
    .div-78 {
      margin-top: 14px;
      font: 16px/24px Josefin Sans, sans-serif;
    }
    @media (max-width: 768px) {
      .div-78 {
        max-width: 100%;
      }
    }
    .div-79 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      .div-79 {
        max-width: 100%;
      }
    }
    .div-80 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      .div-80 {
        max-width: 100%;
      }
    }
    .div-81 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      color: #eee;
    }
    .div-82 {
      color: #fff;
      font: 700 24px/160% Josefin Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-83 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 15px;
    }
    .div-84 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 15px;
    }
    .div-85 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 15px;
    }
    .div-86 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 15px;
    }
    .div-87 {
      font-family: Josefin Sans, sans-serif;
      margin-top: 15px;
    }
  }
}
