.input-fields {
    border: 1px solid #3f3f3f;
    border-radius: 5px;
    height: 25px;
    width: 15vw;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    padding-left: 3px;
    font-weight: 300;
    outline: none;
    background-color: #f5f5f5;
  }

  .applicationGlobalEroor {
    border: 1px solid red;
  }

  .radio-input {
    position: absolute !important;
    margin-top: -10px !important;
    margin-left: 8px !important;
  }

  .input-fields-enabled {
    background-color: white;
  }

  .input-with-adorment {
    /* width: 14vw; */
  }

  .input-width-reduce {
    width: 5vw;
  }

  .input-fields:focus {
    background: white;
  }

  .app-select-input-label {
    width: 17vw;
    max-width: 130px;
    margin-right: 60px;
  }

  .app-input-label {
    width: 15vw;
    display: flex;
    flex-direction: column;
  }

  .app-input-label p {
    font-size: 16px;
  }
  .info-text {
    font-size: 9.5px;
    margin-top: -11px;
  }

  .app-inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 0px 2px 8px;
  }

  @media (max-width: 428px) {
    .app-inputs {
      margin: 8px 0px 8px 0px;
    }
  }

  .copy-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -6px;
    font-size: 13px;
    margin-left: 8px;
    .checkbox {
      padding: 0px;
      padding-right: 5px;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .earlypay-upload-fields {
    margin-block-start: 8px !important;
    margin-block-end: 8px !important;
    width: 100% !important;
    box-sizing: border-box;
  }

  .earlypay-other-uploads {
    margin-block-start: 20px !important;
    margin-block-end: 0px !important;
  }

  .input-expanded {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }

  .decreased-border {
    display: flex;
    align-items: center;
    background-color: rgba(239, 239, 239, 0.3);
    margin-block-start: 17px;
    margin-left: 9px !important;
    padding: 0.1rem 0.1rem;
  }

  .field-note {
    display: flex;
    width: 100%;
    font-size: 13px;
    margin-left: 0.5rem;
    margin-top: 2px;
    .note-icon {
      height: 16px;
      padding-right: 2px;
    }
    .net-salary-note-icon {
      height: 16px;
      padding-right: 2px;
      fill: #0000008a;
    }
  }
  .error-wrapper {
    position: relative;
    .iIcon {
      position: absolute;
      right: -3px;
      top: -6px;
    }
  }

  .smallColon {
    display: none;
  }

  .largeColon {
    margin-left: 5px;
  }

  .remove-border-and-left-margin {
    border-bottom: none !important;
    margin-left: 0px !important;
  }

  .select-inputfield {
    width: 100% !important;
    margin: -5px 0px 0px !important;
    align-self: flex-start;
  }

  .date-picker-textfield {
    width: 15.4vw !important;
    border: 1px solid black !important;
    border-radius: 5px !important;
    margin: 0px !important;
    max-height: 28px;
  }

  .date-picker-globalError {
    width: 16.4vw !important;
    border: 1px solid #d32f2f !important;
    border-radius: 5px !important;
    margin-left: 0px !important;
    max-height: 28px;
  }

  .input-fields-autoComplete {
    /* width: 15.5vw; */
    .MuiAutocomplete-input {
      width: 15vw;
      margin-left: 0px;
    }
  }

  .table-upload-input {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5px;
    width: 40%;
    padding: 1px 4px;
    box-sizing: border-box;
    .placeholder {
      margin: 0px;
    }
    label {
      display: flex;
      align-items: center;
    }
  }

  .type-application,
  .type-auto-complete,
  .type-application-date {
    .verify-icon {
      position: relative;
      height: 18px !important;
      width: 18px !important;
      margin-left: 5px;
      color: rgba(0, 0, 0, 0.6);
      cursor: inherit;
    }
    .emp-approval-required {
      cursor: not-allowed;
    }
    .admin-verified {
      cursor: pointer;
      .verify-icon {
        color: var(--twitter-blue);
      }
    }
    .checker-verify-required {
      cursor: pointer;
    }
    .admin-verify-required,
    .checker-verify-required {
      .verify-icon {
        color: rgb(255, 0, 0, 0.8);
      }
    }
    .all-verified {
      .verify-icon {
        color: var(--disrupt-green);
      }
    }
    .all-verified::after {
      position: relative;
      display: inline-block;
      top: -5px;
      left: -14px;
      content: "";
      color: black;
      background: black;
      height: 8px;
      width: 10px;
      z-index: -1;
    }
    .admin-verify-required {
      cursor: pointer;
    }
    .read-only-verify {
      cursor: default;
    }
    .dummy-verify {
      display: block;
      position: relative;
      height: 18px !important;
      width: 18px !important;
      margin-left: 5px;
      color: rgba(0, 0, 0, 0.6);
      cursor: inherit;
    }
  }

  .type-auto-complete {
    .verify-wrapper {
      display: flex;
      align-items: center;
      /* margin-left: 3px; */
      .verify-icon {
        margin-left: 5px;
      }
    }
    .all-verified::after {
      top: 0px;
    }
  }

  .type-application-date {
    .verify-wrapper {
      display: flex;
      align-items: center;
      margin-left: 8px;
      .verify-icon {
        margin-left: -3px;
      }
    }
    .all-verified::after {
      top: 0px;
    }
  }

  @media (max-width: 428px) {
    .type-application-date {
      .verify-wrapper {
        .verify-icon {
          margin-left: 7px;
        }
      }
    }
  }

  .applicationCopyCheckbox {
    position: absolute;
    font-size: 12px;
    top: 20px;
    display: flex;
    align-items: center;
    .checkbox {
      padding-left: 0px;
      padding-right: 5px;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (max-width: 1030px) {
    .applicationCopyCheckbox {
      font-size: 10px;
    }
  }

  @media (max-width: 900px) {
    /* .applicationCopyCheckbox {
      margin-left: 6vw;
    } */
  }
  @media (max-width: 978px) {
    .error-wrapper,
    .date-picker-textfield {
      margin-left: 6vw;
    }
    .date-picker-textfield,
    .date-picker-globalError {
      margin-left: 6vw !important;
    }
  }

  @media (max-width: 428px) {
    .input-fields {
      font-size: 3vw;
      width: 35vw;
      margin-left: 0px;
    }
    .info-text {
      font-size: 2.5vw;
      margin-top: -14px;
    }
    .date-picker-textfield,
    .date-picker-globalError {
      width: 36vw !important;
      margin: 8px 0px 8px 6vw !important;
      font-size: 3vw !important;
    }
    .input-fields-autoComplete {
      .MuiAutocomplete-input {
        width: 35vw !important;
        font-size: 3vw !important;
      }
    }
    .date-picker-inputField {
      font-size: 3vw !important;
    }
    .app-input-label {
      width: 40vw;
    }
    .app-input-label p {
      font-size: 3vw;
    }
    .largeColon {
      display: none;
    }
    .smallColon {
      display: flex;
      margin-right: 3.5vw;
    }
    .copy-checkbox {
      font-size: 13px;
    }
    .input-expanded {
      display: none;
    }
    .field-note {
      font-size: 11px;
    }
    .applicationTypeHelperText {
      font-size: 0.5rem;
    }
    .applicationCopyCheckbox {
      margin-left: 0vw;
      font-size: 9px;
    }
  }

  @media (max-width: 360px) {
    .info-text {
      margin-top: -8px;
    }
  }
